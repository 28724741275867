import { BaseHttpService } from '@shared/abstractions/base-http.service';
import { Injectable } from '@angular/core';
import UserDto from '@shared/services/users/dto/user.dto';
import { UsersPagedQueryDto } from '@shared/services/users/dto/users-paged-query.dto';
import CreateUserDto from '@shared/services/users/dto/create-user.dto';
import { Observable } from 'rxjs';
import { HelpCrunchContact } from '@shared/widgets/help-crunch-widget.service';
@Injectable({
  providedIn: 'root',
})
export default class UsersApiService extends BaseHttpService {
  constructor() {
    super('users');
  }

  public getAllPaged(query: UsersPagedQueryDto) {
    return this.getPaged<UserDto, UsersPagedQueryDto>('', query);
  }

  public create(body: CreateUserDto) {
    return this.post<UserDto>('', body);
  }

  public updateById(id: string, body: Partial<CreateUserDto>) {
    return this.put<UserDto>(id, body);
  }

  public deleteById(id: string) {
    return this.delete(id);
  }

  public restoreById(id: string) {
    return this.post(`restore/${id}`);
  }

  public getContactInfoForHelpCrunchWidget(userId: string): Observable<HelpCrunchContact> {
    return this.get<HelpCrunchContact>(`${userId}/helpcrunch`);
  }
}
